import React, { useEffect, useRef, useState } from "react"
import * as styles from "./wissen.module.scss";

import Layout from "../page-components/layout"
import { graphql } from "gatsby"
import RcDefaultPageTeaser from "../page-components/teaser/rcDefaultPageTeaser"
import RcCard from "../components/card/rcCard"
import RcCardFull from "../components/card/rcCardFull"

import CalendarSVG from "../icons/fontawesome/light/calendar-alt.svg";
import FeatherAltSVG from "../icons/fontawesome/light/feather-alt.svg";
import StarSVG from "../icons/fontawesome/light/star.svg";

import * as excerptHtml from "excerpt-html";

const WissenPage = ( { data }) => {

  const categorySelectRef = useRef(null);

  const nodes = data.allWpWissen.nodes;
  const allCategories = nodes.reduce( (acc, cur) => [ ...acc, ...cur.acf_elements.category], [] )
                             .filter( (c, idx, arr) => arr.indexOf(c) === idx );

  const [ filterCategory, setFilterCategory ] = useState(null);
  const [ firstNNodes, setFirstNNodes ] = useState( nodes.filter( (n, i) => i < 3 ) );
  const [ lastNNodes, setLastNNodes ] = useState( nodes.filter( (n, i) => i >= 3 ) )

  useEffect(
    () => {
      const filteredNodes = filterCategory === null ? data.allWpWissen.nodes : data.allWpWissen.nodes.filter(
        (n) => {
          for (let c of n.acf_elements.category) {
            if (c === filterCategory) return true;
          }
          return false;
        }
      );

      setFirstNNodes( filteredNodes.filter( (n, i) => i < 3 ) )
      setLastNNodes( filteredNodes.filter( (n, i) => i >= 3 ) )
    },
    [ filterCategory ]
  );

  useEffect(
    () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      if (urlParams.has('category')) {
        setFilterCategory(urlParams.get('category'));

        if (categorySelectRef.current) {
          categorySelectRef.current.value = urlParams.get("category");
        }
      }
    },
    []
  );

  return (
    <Layout>

      <RcDefaultPageTeaser>
        <h1>Wissen - zum Erfolg</h1>

        <div dangerouslySetInnerHTML={
          {
            __html: data.wp.texteFRSektionen.introwissen
          }
        } />

        <p>
          In diesem Abschnitt finden Sie frei zugängliche Wissensbeiträge, die Mitarbeiter und Partner der Reischauer Consulting in unregelmäßigen Abständen veröffentlichen.
          <br />
          Das Veröffentlichungsdatum weist den Wissensstand zum bekannt gegebenen Termin aus.
        </p>
      </RcDefaultPageTeaser>


      <div className="container bg-primary-dark text-white py-4">
        <div className="row">
          <div className="col-12">


              <div className="rc-form-control">
                <label className={"mr-2"}>Kategorie</label>

                <select className={styles.select}
                        ref={categorySelectRef}
                        onChange={
                          e => {
                            if (e.target.value === "all") {
                              setFilterCategory(null);
                            } else {
                              setFilterCategory(e.target.value);
                            }
                          }
                        }>
                  <option value={"all"}>Alle</option>
                  { allCategories.map( c => <option>{c}</option>)}
                </select>
              </div>


          </div>
        </div>

      </div>

      <div className="container bg-white">

        <div className="row">
        { firstNNodes.map(
          n => <RcCardFull className={"col-12 mt-4"}
                           to={"/wissen/" + n.slug}
                           actionText={"Mehr lesen ..."}
                           children={n.acf_elements.description}
                           caption={n.title}
                           premium={n.acf_elements.premium}
                           subCaption={
                             <div className={styles.subcaption + " mb-4"}>
                               <div className="d-flex align-items-center">
                                 <CalendarSVG className={"mr-1 svgFill"} />
                                 {n.date}
                               </div>

                               { n.acf_elements.autor && <div className={"d-flex align-items-center"}>
                                 <FeatherAltSVG className={"mr-1 svgFill"} />
                                 {n.acf_elements.autor}
                               </div>}

                               { n.acf_elements.premium && <div className={"d-flex align-items-center"}>
                                 <StarSVG className={"mr-1 svgFill"} />
                                 Premium-Artikel
                               </div>}

                             </div>

                           }
                           fluid={n.acf_elements.header?.localFile.childImageSharp.gatsbyImageData} />
        )}
        </div>

        <div className="row bg-white pt-0 pb-5 py-lg-3 py-lg-5">

            { lastNNodes.map(
              n => {
                return (
                  <RcCard to={"/wissen/" + n.slug}
                                 className={"mt-3"}
                                 caption={n.title}

                                 description={ excerptHtml(n.content, { pruneLength: 100 } ) }

                                 children={

                                   <>
                                     <div className={styles.subcaption + " mt-2 d-flex align-items-center"}>
                                       <CalendarSVG className={"mr-1 svgFill"} /> {n.date}
                                     </div>

                                     {n.acf_elements.autor &&
                                     <div className={styles.subcaption + " mt-1 d-flex align-items-center"}>
                                       <FeatherAltSVG className={"mr-1 svgFill"}/> {n.acf_elements.autor}
                                     </div>
                                     }

                                   </>

                                 }
                                 img={n.acf_elements.header.localFile.childImageSharp.gatsbyImageData} />
                );
              }
            )}

        </div>
      </div>


    </Layout>
  );

};

export default WissenPage;


export const query = graphql`
  query WissenListQuery {
  
    wp {
      texteFRSektionen {
          acf {
            introwissen
          }
      }
    }
  
    allWpWissen(sort: {fields: date, order: DESC}) {
      nodes {
        id
        slug
        title
        content
        
        date(formatString: "DD.MM.YYYY")
        
        acf_elements {
          description
          premium
          autor
          header {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          category
        }
      }
    }
}

`
